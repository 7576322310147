import React, { useState } from 'react';
import {Button, Col, Form, FormFeedback, Input, Label, Row, Spinner} from "reactstrap";
import {useSelector} from "react-redux";
import { withTranslation } from 'react-i18next';
import {useFormik} from "formik";
import * as Yup from "yup";
import {Navigate} from 'react-router-dom';
import { createCostApi } from '../../../helpers/api/costs.api';
import Flatpickr from "react-flatpickr";
import { format } from 'date-fns';
import { notify } from '../../../helpers/general_helper';

const CreateCostsForm = ({isModal = false, toggle, redirectTo = '', t}) => {
	const companyId = useSelector(s => s.Company.loggedInCompany.ID);
	const [loading, setLoading] = useState(false);
	const [finishedCreate, setFinishedCreate] = useState(false);
	const [data, setData] = React.useState({
		name: "",
		date: new Date(),
		amount: '',
		note: '',
	});

	const validation = useFormik({
		initialValues: data,
		enableReinitialize: true,
		validationSchema: Yup.object({
			name: Yup.string().required(t("Name is required")),
			amount: Yup.string().required(t("Amount is required")),
			date: Yup.string().required(t("Date is required")),
			note: Yup.string(),
		}),
		onSubmit: async (values) => {
			try {
				setLoading(true);
				values.amount = parseFloat(values.amount);
				values.date = format(values.date, 'yyyy-MM-dd');
				const resp = await createCostApi(companyId, values)

				notify(resp.message, {type: "success"})
				setFinishedCreate(true);
			} catch (e) {
				notify(e.message, {type: "error"})
			} finally {
				setLoading(false);
			}
		}
	});

	const handleChange = (e) => {
		const {name, value} = e.target;
		if (name.includes('.')) {
			const [parent, child] = name.split('.');
			const newData = data;
			newData[parent][child] = value;

			return setData(newData);
		}

		setData({...data, [name]: value});
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		validation.handleSubmit();
	}

	return (
		<Form className="form-steps" onSubmit={handleSubmit}>
			{finishedCreate && redirectTo ? (
				<Navigate to={redirectTo} />
				) : null}
			<Row>
				<Col md={6}>
					<div className="mb-3">
						<Label for="name" className="form-label">{t("Name")}</Label>
						<Input
							name="name"
							type="text"
							className="form-control d-block"
							aria-label="Name text"
							onBlur={validation.handleBlur}
							invalid={validation.errors.name && validation.touched.amount}
							onChange={handleChange}
							value={data.name}
						></Input>
						{validation.errors.name && validation.touched.amount? (
							<FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
						) : null}
					</div>
				</Col>
				<Col md={6}>
					<div className="mb-3">
						<Label for="amount" className="form-label">{t("Amount")}</Label>
						<Input
							name="amount"
							type="number"
							className="form-control d-block"
							aria-label="Default select example"
							onBlur={validation.handleBlur}
							invalid={validation.errors.amount && validation.touched.amount}
							onChange={handleChange}
							value={data.amount}
						></Input>
						{validation.errors.amount && validation.touched.amount? (
							<FormFeedback type="invalid">{validation.errors.amount}</FormFeedback>
						) : null}
					</div>
				</Col>
				<Col md={6} xl={6} className="mb-3">
					<div className="mb-3">
						<Label for="date" className="form-label">{t("Expense date")}</Label>
						<Flatpickr
							className="form-control"
							id="event-start-date"
							name="date"
							placeholder="Select Date"
							value={validation.values.date || ""}
							onChange={(e) => setData({...data, date: e[0]})}
							options={{
								dateFormat: "d/m/Y",
								locale: {
									firstDayOfWeek: 1
								}
							}}
						/>
					</div>
				</Col>
				<Col md={12} xl={12} className="mb-3">
					<div className="mb-3">
						<Label for="note" className="form-label">{t("Note")}</Label>
						<Input
							type="textarea"
							name="note"
							className="form-control"
							id="note"
							placeholder={t("Note")}
							onChange={handleChange}
						/>
					</div>
				</Col>
				<Col xs={12}>
					<div className="text-end">
						{
							isModal ? (
								<button type="button" onClick={toggle} className="btn btn-ghost-dark" style={{marginRight: 3}}>Cancel</button>
							) : null
						}
						<Button type="submit" color="primary" className="btn-load" disabled={loading}>
							<span className="d-flex align-items-center">
								<span className={"flex-grow-1 " + (loading ? "me-2" : "")}>
									{t("Create")}
								</span>
								{loading ? ( <Spinner size="sm" className="flex-shrink-0" role="status" /> ) : null}
							</span>
						</Button>
					</div>
				</Col>
			</Row>
		</Form>
	)
}

export default withTranslation()(CreateCostsForm);
